@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
@import "./abstracts/variables";
@import "bootstrap/scss/bootstrap.scss";

:root {
  --second-bg-color: #323946;
  --main-color: #0ef;
}

img[src="https://cdn.000webhost.com/000webhost/logo/footer-powered-by-000webhost-white2.png"]
{
  display: none !important;
}

.profile {
  width: 300px;
  height: 300px;
}

@include media-breakpoint-down(md) {
  .profile {
    width: 250px;
    height: 250px;
    margin-top: 3rem;
  }
}
